import CtaLinkItem, { getButtonClass } from '@/common/interfaces/CtaLinkItem';
import { CtaInlineBlockViewModel } from '@/modules/CtaInlineModule/CtaInlineModule.types';
import { getStore } from '@/store';

export interface ArticleCtaProps {
  NewsletterSingUpCta?: CtaInlineBlockViewModel;
}

export const getArticleCta = (
  { ButtonStyle, Target, Link, Title, Text }: CtaLinkItem,
  ArticleText: string,
  moduleId: string | number,
  ) => {
  const state = getStore().state;
  const isArticle = state.Meta.Analytics.page_type_name === 'JournalArticlePage';
  const Modules = state.Modules.PageData.Slots[1].Modules;
  const firstWithCtaIndex = Modules.findIndex(m => m.Data.NewsletterSingUpCta)
  const firstWithCta = Modules[firstWithCtaIndex]
  const ctaInlineModuleIndex = Modules.findIndex(m => m.Type === 'CtaInlineModule')

  if (ctaInlineModuleIndex !== -1 && (firstWithCtaIndex + 1 === ctaInlineModuleIndex || firstWithCtaIndex - 1 === ctaInlineModuleIndex)) {
    return ArticleText
  }
  
  if (firstWithCta.ContentLinkId !== moduleId) {
    return ArticleText
  }

  const blockquoteSanitized = ArticleText
  .replace(/<blockquote><p/, '<blockquote><span')
  .replace(/<\/p><\/blockquote>/, '</span></blockquote>')

  const buttonClass = getButtonClass(ButtonStyle)
  const linkTarget = Target ? Target : '_self'
  const linkText = Text ? `<span style="display:block;margin-bottom:7px;letter-spacing:normal;text-transform:none" class="h5">${Text}</span>` : ''
  const btn = `
    <span style="text-align:center;display:block;margin:20px 0;border-bottom:.4px solid #d8d8d8;border-top:.4px solid #d8d8d8;padding:25px 0;width:100vw;position:relative;left:50%;right:50%;margin-left:-50vw;margin-right:-50vw">
      ${linkText}
      <a style="margin-left:auto;margin-right:auto" href="${Link}" target="${linkTarget}" class="button ${buttonClass}">${Title}</a>
    </span>
  `;
  return Title && isArticle ? blockquoteSanitized.replace(/<\/p>/, btn) : ArticleText;
}