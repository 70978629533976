import { Component, Vue } from 'vue-property-decorator';

import { getComponentProps } from '@/utils/vueUtils';
import TextGeneric from '@/components/TextGeneric/TextGeneric.vue';
import TextGenericItem from '@/common/interfaces/TextGenericItem';
import { getArticleCta } from '@/components/ArticleText/ArticleText.utils';

const ContentTextGenericModuleProps = Vue.extend({
  props: getComponentProps<TextGenericItem>({
    Text: '<p>Html text</p>',
    IsCentered: false,
    IsDropLetter: false,
  }),
});

@Component({
  components: {
    TextGeneric,
  },
})
export default class PlainHtmlModule extends ContentTextGenericModuleProps {
  mounted() {
    this.$nextTick(() => setTimeout(() => this.fixBrokenEmbeds(), 150));
  }
  get TextData() {
    const { NewsletterSingUpCta, Text: ArticleTextRaw } = this.p
    const ArticleTextTableFix = ArticleTextRaw && ArticleTextRaw.replace(/<table/g, '<table role="presentation"')
    const ArticleText = ArticleTextTableFix && ArticleTextTableFix.replace(/.pdf"/g, `.pdf" title="PDF download"`).replace(/.aspx"/g, '.aspx?quality=50&maxwidth=1920&format=jpg" style="height:auto"')
    if (!NewsletterSingUpCta) return ArticleText
    const { ButtonStyle, Target, Link, Text, Title } = this.p.NewsletterSingUpCta.Button

    return getArticleCta(
      { ButtonStyle, Target, Link, Text, Title },
      ArticleText,
      this.$attrs.contentLinkId,
    )
  }
  fixBrokenEmbeds() {
    const wasNotTwitterRendered = document.querySelector('blockquote.twitter-tweet');
    const wasNotInstagramRendered = document.querySelector('blockquote.instagram-media')
    
    if (wasNotTwitterRendered) {
      const twitter = document.createElement('script');
      twitter.src = 'https://platform.twitter.com/widgets.js';
      document.head.appendChild(twitter);
    }

    if (wasNotInstagramRendered) {
      const instagram = document.createElement('script');
      instagram.src = '//www.instagram.com/embed.js';
      document.head.appendChild(instagram);
      try {
        // @ts-ignore
        instgrm.Embeds.process();
      } catch (e) {}
    }
  }
}
