import { Component, Vue } from 'vue-property-decorator';

import TextGenericItem from '@/common/interfaces/TextGenericItem';
import { getArticleCta } from '@/components/ArticleText/ArticleText.utils';

const TextGenericProps = Vue.extend({
  props: {
    item: Object as () => TextGenericItem,
  },
});
@Component
export default class TextGenericComponent extends TextGenericProps {
  get TextData() {
    const { NewsletterSingUpCta, Text: ArticleTextRaw } = this.$props.item
    const ArticleTextTableFix = ArticleTextRaw && ArticleTextRaw.replace(/<table/g, '<table role="presentation"')
    const ArticleTextImg = ArticleTextTableFix && ArticleTextTableFix .replace(/.pdf"/g, `.pdf" title="PDF download"`)
    const ArticleText = ArticleTextImg?.replace(/.aspx"/g, '.aspx?quality=50&maxwidth=1920&format=jpg" style="height:auto"')
    if (!NewsletterSingUpCta) return ArticleText
    const { ButtonStyle, Target, Link, Text, Title } = this.$props.item.NewsletterSingUpCta.Button

    return getArticleCta(
      { ButtonStyle, Target, Link, Text, Title },
      ArticleText,
      this.$parent.$attrs.contentLinkId,
    )
  }
}
